console.log('If you are looking for a job, contact @wowi42 on libera.chat');
import HandyCollapse from "handy-collapse";
const myAccrodionCustom = new HandyCollapse({
    closeOthers: false
});

const elements = document.getElementsByClassName("button");
for (let element of elements) {
    let clicked = false;
    element.addEventListener("click", function() {
        if (!clicked) {
            element.firstChild.classList.replace('fa-plus', 'fa-minus');
        } else {
            element.firstChild.classList.replace('fa-minus', 'fa-plus');
        }
        clicked = !clicked;
    });
}
